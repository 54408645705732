import ReactMarkdown from 'react-markdown';
import React from 'react';
import tryParseJson from '@/utils/tryParseJson';
import summarySchema from './summarySchema';
import { parseGptJson } from '../../lib';
import personaForm from '../shared/personaForm';
import personaInsights from '../shared/personaInsights';
import getPersonaResultMarkdown from '../shared/getPersonaResultMarkdown';
import personaSchema from '../shared/personaSchema';

const mapEntity = (entity) => `Entity: ${entity.name}, Subject: ${entity.subtype}, Score: ${entity.query.affinity}`;

const mapTag = (entity) => `Tag Genre: ${entity.name}, Subject: ${entity.subtype}, Score: ${entity.query.affinity}`;

const personas = {
  name: 'Personas',
  description: 'Generate personas with action plans across various industries',
  form: personaForm,
  insights: personaInsights,
  render: ({ result }) => {
    const markdown = result ? getPersonaResultMarkdown(result) : '';
    return (
      <ReactMarkdown>
        {markdown}
      </ReactMarkdown>
    );
  },
  runner: async ({
    entities, fetchGpt, tags, userData,
  }) => {
    const { personaCount } = userData;
    const summaryPrompt = `
    given these breakdowns of affinity scores for genre tags and entities,
    give ${personaCount} distinct persona summaries that represents this taste
    tag inputs: ${tags.map(mapTag).join(' ')}
    entity inputs: ${entities.map(mapEntity).join(' ')}
    ** instructions:
    * data grounding: Each persona must be entirely based on the provided tags, entities, and their affinity scores.
    * avoid narratives: Do not name personas or include personal backstories.
    ** key requirements:
    * distinctiveness: Ensure each persona is unique. Overlap should only occur when reflecting overarching themes.
    return a json array of summaries that follow this schema: ${JSON.stringify(summarySchema)}. the result must be json
    `;

    const summaryResult = await fetchGpt({ prompt: summaryPrompt });

    const summaryResultJson = parseGptJson(summaryResult);
    const { summaries } = tryParseJson(summaryResultJson);

    const personaResults = await Promise.all(summaries.map(async (summary) => {
      const personaPrompt = `
      given this summary of a persona,
      give a distinct persona that represents this taste, the persona must directly reflect the provided data.
      summary: ${summary}
      ** instructions:
      * data grounding: Each persona must be entirely based on the provided summary
      * always directly connect the mentioned summaries
      * avoid narratives: Do not name personas or include personal backstories.
      ** key requirements:
      * distinctiveness: Ensure each persona is unique in their projected passions, predicted interests, actionable insights, and examples. Overlap should only occur when reflecting overarching themes.
      * transparency: always include affinity scores with examples to justify their relevance.
      * consistency: ensure no inferred details are added beyond the input data.
      the result should follow this schema: ${JSON.stringify(personaSchema)}
      return the output only as a single json object
      `;

      const personaResult = await fetchGpt({ prompt: personaPrompt });
      const personaResultJson = parseGptJson(personaResult);
      return JSON.parse(personaResultJson);
    }));

    return {
      result: personaResults,
    };
  },
};

export default personas;
