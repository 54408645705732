import React, { useRef, useState } from 'react';
import CategorySelect from '../CategorySelect';
import OmnisearchResults from './OmnisearchResults';
import OmnisearchSearchInput from './OmnisearchSearchInput';
import useOmnisearchResults from './useOmnisearchResults';
import OmnisearchItemChip from '../OmnisearchItemChip';
import styles from './Omnisearch.module.scss';

const OmnisearchInner = ({ downshift, onChange, value }) => {
  const {
    getInputProps,
    getItemProps,
    getMenuProps,
    isOpen,
    toggleMenu,
  } = downshift;

  const inputRef = useRef(null);
  const inputProps = getInputProps({ ref: inputRef });

  const [search, setSearch] = useState('');
  const [category, setCategory] = useState('');

  const handleSearchInputChange = (inputValue, e) => {
    inputProps.onChange(e);
    setSearch(inputValue);
  };

  const omnisearchResults = useOmnisearchResults({ search, category });

  const handleDeleteValueByIndex = (index) => () => {
    const nextValue = [...value];
    nextValue.splice(index, 1);
    onChange(nextValue);
  };

  const handleClickSearchInput = () => {
    toggleMenu();
    if (!isOpen) {
      inputRef.current?.focus();
    }
  };

  const handleSelectCategory = (urn) => {
    setCategory(urn);
  };

  return (
    <>
      <div className={styles.fieldsContainer}>
        <OmnisearchSearchInput
          inputOnChange={handleSearchInputChange}
          inputProps={inputProps}
          isLoading={omnisearchResults.isLoading}
          onClick={handleClickSearchInput}
          search={search}
        />
        <CategorySelect value={category} onChange={handleSelectCategory} className={styles.categorySelect} />
      </div>
      {(value && !!value.length) && (
        <div className={styles.chipsContainer}>
          {value.map((item, i) => (
            <OmnisearchItemChip key={item.id} item={item} onDelete={handleDeleteValueByIndex(i)} />
          ))}
        </div>
      )}
      {!!search && !omnisearchResults.isLoading && (
        <OmnisearchResults
          getItemProps={getItemProps}
          getMenuProps={getMenuProps}
          omnisearchResults={omnisearchResults}
          value={value}
        />
      )}
    </>
  );
};

export default OmnisearchInner;
