import useFetch from './useFetch';

const cleanResult = (result) => result.replace(/^```(markdown)?/, '').replace(/```$/, '');

const useGPTRaw = (query, config = {}) => {
  const url = `/gpt?prompt=${encodeURIComponent(query)}`;
  return useFetch(
    url,
    {
      key: query,
      ...config,
    },
  );
};
export { useGPTRaw };

const useGPT = (...params) => {
  const { result, ...rest } = useGPTRaw(...params);

  return {
    result: cleanResult(result?.body?.results?.choices?.[0]?.message.content?.trim?.() || ''),
    ...rest,
  };
};
export default useGPT;
