export const barChartTagTypesBitmap = {
  'urn:tag:complexity:qloo': 1,
  'urn:tag:obscurity:qloo': 1,
};

export const HIGHLIGHT_DATA = [
  {
    category: 'urn:entity:brand',
    tags: [
      { tagValue: 'urn:tag:genre:brand:fashion', name: 'Fashion' },
      { tagValue: 'urn:tag:genre:brand:retail', name: 'Retail' },
    ],
  },
  {
    category: 'urn:entity:place',
    tags: [
      { tagValue: 'urn:tag:genre:place:restaurant', name: 'Restaurants' },
      { tagValue: 'urn:tag:genre:place:hotel', name: 'Hotels' },
    ],
  },
];

export const CANONICAL_TAG = 'urn:tag:collection:canonical';
