import { VeltCommentsSidebar, VeltSidebarButton } from '@veltdev/react';
import useTheme from '@/hooks/useTheme';

const filterConfig = {
  location: {
    enable: false, // enable/disable location filter
    name: 'Pages', // customize location filter heading
    enableGrouping: true, // to enable/disable grouping based on location filter
    multiSelection: false, // to enable/disable multiple selection
    order: ['locationId1', 'locationId2', 'locationId3'], // pass array of location ids here
  },
  people: {
    enable: true, // enable/disable people filter
    name: 'People', // customize people filter heading
    enableGrouping: false, // to enable/disable grouping based on people filter
    multiSelection: false, // to enable/disable multiple selection
  },
  priority: {
    enable: false, // enable/disable priority filter
    name: 'Priority', // customize priority filter heading
    enableGrouping: false, // to enable/disable grouping based on priority filter
    multiSelection: false, // to enable/disable multiple selection
  },
  category: {
    enable: false, // enable/disable category filter
    name: 'Category', // customize category filter heading
    enableGrouping: false, // to enable/disable grouping based on category filter
    multiSelection: false, // to enable/disable multiple selection
  },
};

const groupConfig = {
  enable: false, // to enable/disable group by option
  name: 'Custom Group By', // customize group by heading
};

const CommentsSidebar = () => {
  const { isDarkMode } = useTheme();

  return (
    <>
      <VeltSidebarButton darkMode={isDarkMode} />
      <VeltCommentsSidebar filterConfig={filterConfig} groupConfig={groupConfig} />
    </>
  );
};

export default CommentsSidebar;
