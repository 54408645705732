import { useState, useEffect } from 'react';
import useGPT from '@v1/lib/useGPT';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import MarkdownView from './MarkdownView';
import styles from '../CreativesModal.module.scss';
import MarkdownWithCitations from './MarkdownWithCitations';

const useSummarizeByGPT = (text) => {
  const { result } = useGPT(`
        Summarize this in under 10 words.
        ${text}
    `, {
    key: `gpt-v2-${text}`,
    required: [text],
  });
  return { result };
};

const TLDR = ({ text }) => {
  const { result: tldr } = useSummarizeByGPT(text);
  return tldr && (
    <div className={styles.tldr}>
      <AutoAwesomeIcon />
      {tldr}
    </div>
  );
};

const MarkdownSection = ({
  header, content, entities, tags, baseParams, extraCitations,
}) => {
  return (
    <>
      <MarkdownView>{header}</MarkdownView>
      <TLDR text={content} />
      <MarkdownWithCitations
        entities={entities}
        extraCitations={extraCitations}
        tags={tags}
        markdown={content}
        baseParams={baseParams}
      />
    </>
  );
};

// const untab = (markdown) => markdown.replace(/^(\s*)/gm, '');

const MarkdownTLDR = ({
  markdown, entities, tags, baseParams, extraCitations = [],
}) => {
  const [sections, setSections] = useState([]);

  useEffect(() => {
    if (!markdown) {
      setSections([]);
      return;
    }

    const lines = markdown.trim().split('\n');

    const collect = [];
    let currentSection = { header: '', content: '' };

    // eslint-disable-next-line no-restricted-syntax
    for (const line of lines) {
      if (line.trim().match(/^##\s+/)) {
        if (currentSection.content.length) {
          collect.push(currentSection);
          currentSection = { header: '', content: '' };
        }
        currentSection.header = line;
      } else {
        currentSection.content += `${line}\n`;
      }
    }

    if (currentSection.content.length) {
      collect.push(currentSection);
    }

    setSections(collect);
  }, [markdown]);

  return (
    <div>
      {sections.map((section, index) => (
        <MarkdownSection
          key={index}
          extraCitations={extraCitations}
          header={section.header}
          content={section.content}
          entities={entities}
          tags={tags}
          baseParams={baseParams}
        />
      ))}
    </div>
  );
};

export default MarkdownTLDR;
