import form from './formSchema.json';
import formUiSchema from './formUiSchema.json';
import runner from './runner';
import render from './Render';

const Spokesperson = {
  name: '👤 Spokesperson selection',
  description: 'Select a spokesperson for your campaign or media',
  form,
  formUiSchema,
  defaultFormData: {
    Categories: [{ name: 'People' }],
    instructions: 'commercial for alcoholic beverage',
  },
  insights: {
    entities: [
      { category: 'person', params: { take: 20 } },
    ],
    tags: [
      {
        category: 'brand',
        fetchByTagTypes: true,
        params: {
          take: 5,
        },
      },
    ],
  },
  extraCitations: ({ adjectives }) => [
    {
      label: 'Adjectives for these people',
      value: `${adjectives.split(', ').map((adjective) => `- ${adjective}`).join(', ')}`,
    },
  ],
  render,
  runner,
};

export default Spokesperson;
