import { useMemo } from 'react';
import OmnisearchItemChip from '../OmnisearchItemChip';

const EntityChip = ({ entity, ...props }) => {
  const item = useMemo(() => ({
    id: entity.entity_id,
    name: entity.name,
    types: entity.types,
  }), [entity]);

  return (
    <OmnisearchItemChip item={item} {...props} />
  );
};

export default EntityChip;
