export default {
  strict: true,
  required: [
    'summary',
    //        "demographicsBreakdown",
    'description',
    'interests',
    'recommendations',
  ],
  additionalProperties: false,
  properties: {
    summary: {
      type: 'string',
      $comment: 'A 3 sentence summary of the persona. Ensure the summary is grounded in the provided tags and entities. Avoid overly abstract or overly imaginative language.',
    },
    //  "demographicBreakdown": {
    //      "type": "string",
    //      "$comment": "gender: male, female, no preference\
    //      age range: <= 24, 25-29, 30-34, 35-44, 45-54, 55+ (can be multiple ranges)\
    //    income level, propensity to spend, etc"
    // },
    description: {
      type: 'object',
      $comment: "A focused description of the persona's practical preferences and lifestyle. Include overarching themes inferred from the data",
      properties: {
        overview: {
          type: 'string',
          $comment: '2-3 sentences summarizing their lifestyle and interests based on data-driven themes. Include interests, experiences, local activities',
        },
        projectedPassions: {
          type: 'string',
          $comment: '2-3 sentences inferring and emphasizing key activities or interests based on the data provided. Avoid inserting unrelated activities.',
        },
        predictedInterests: {
          type: 'string',
          $comment: "2-3 primary interests and motivations the persona is likely to engage in, based on the input data.  Ensure interests are specific to the input data's context",
        },
      },
    },
    interests: {
      type: 'object',
      $comment: 'Breakdown of the personas preferences, using specific examples of entities, tags and their relevant affinity scores.',
      properties: {
        lifestyleBrands: {
          type: 'string',
          $comment: 'Provide 3–5 examples of relevant lifestyle brands, each tied to an affinity score and a brief explanation of why it resonates with the persona.',
          // $comment: 'Describe the types of lifestyle brands this persona connects with, supported by affinity scores and tags.\
          //            Include 5 examples of relevant brands and tags with affinity scores, explaining why these brands resonate with the persona based on their lifestyle and interests.',
        },
        frequentPlaces: {
          type: 'string',
          $comment: 'Provide 3–5 examples of relevant places, each tied to an affinity score and a brief explanation of why it resonates with the persona.',
          // $comment: 'Detailed insights into the type of the personas preferred venues and locations (e.g., restaurants, event spaces).\
          //           Include 5 examples of relevant places and tags with affinity scores, explaining why these resonate with the persona based on their interests.',
        },
        entertainment: {
          type: 'string',
          $comment: "Provide 3–5 media preferences (movies, TV shows, events) and explain how these align with the persona's traits and affinity scores.",
          // $comment: 'Describe the types of movies, events, or cultural activities this persona enjoys.\
          //           Include 5 examples of relevant media and tags with affinity scores, explaining why these resonate with the persona based on their interests.',
        },
        readingPodcasts: {
          type: 'string',
          $comment: 'Provide 3–5 books, podcasts, or other media they consume, with reasons tied to their affinity scores.',
          // $comment: 'Discuss their reading habits and podcast preferences.\
          //            Include 5 examples of relevant books, podcasts and tags with affinity scores, explaining why these resonate with the persona based on their interests.',
        },
        music: {
          type: 'string',
          $comment: 'Highlight 3–5 musical artists or genres the persona enjoys, using affinity scores to explain why these are relevant.',
          // $comment: 'Highlight the music genres and artists they enjoy.\
          //            Include 5 examples of relevant music with affinity scores, explaining why these resonate with the persona based on their interests.',
        },
      },
    },
    recommendations: {
      type: 'object',
      $comment: "Actionable takeaways tailored to this persona's traits and preferences.",
      properties: {
        actionableInsightsRealEstate: {
          type: 'string',
          $comment: 'Explain how real estate offerings can be customized for this persona.',
        },
        actionableInsightsCPG: {
          type: 'string',
          $comment: "Describe product opportunities that cater to this persona's preferences.",
        },
        actionableInsightsEntertainment: {
          type: 'string',
          $comment: "Provide recommendations for entertainment options tailored to the persona's interests.",
        },
        actionableInsightsMedia: {
          type: 'string',
          $comment: 'Offer media strategies targeting the persona.',
        },
        actionableInsightsRetail: {
          type: 'string',
          $comment: "Recommend retail solutions tailored to the persona's shopping habits.",
        },
        actionableInsightsAgencies: {
          type: 'string',
          $comment: 'Suggest strategies for agencies to engage with the persona.',
        },
        idealAmenities: {
          type: 'array',
          $comment: 'Descriptions of the ideal amenities for the persona.',
          items: {
            type: 'string',
            $comment: 'List 2-3 specific amenities that would appeal to this persona, with descriptions of why they are relevant.',
          },
        },
      },
    },
  },
};
