import { mkConfig, generateCsv, download } from 'export-to-csv';
import isObject from '@/utils/isObject';
import { filterTypes, paramNames } from '@/constants';

const isValidCsvValue = (value) => {
  return (
    typeof value === 'string'
    || typeof value === 'number'
    || typeof value === 'boolean'
    || value === null
    || value === undefined
  );
};

const transformEntityForCsv = (entity) => {
  return Object.fromEntries(
    Object.entries(entity).map(([key, value]) => {
      if (isValidCsvValue(value)) {
        return [key, value];
      }
      if (isObject(value)) {
        return [key, JSON.stringify(value)];
      }
      return [key, value?.toString() ?? null];
    }),
  );
};

const transformHeatmapForCsv = (item, heatmapType) => {
  const { latitude, longitude } = item.location;
  const value = item.query[heatmapType];

  return {
    latitude,
    longitude,
    value,
  };
};

const formatCsvData = (data, panel) => {
  const filterType = panel.params[paramNames.filterType];
  const isHeatmap = filterType === filterTypes.heatmap;

  if (isHeatmap) {
    const heatmapType = panel.settings?.heatmapControls?.heatmapType || 'affinity';

    return data.results.heatmap.map((item) => transformHeatmapForCsv(item, heatmapType));
  }

  return data.results.entities.map(transformEntityForCsv);
};

const downloadCsv = (fileName, data, panel) => {
  const csvData = formatCsvData(data, panel);

  const csvConfig = mkConfig({
    fieldSeparator: ',',
    filename: fileName,
    decimalSeparator: '.',
    useKeysAsHeaders: true,
  });

  const csv = generateCsv(csvConfig)(csvData);

  download(csvConfig)(csv);
};

export default downloadCsv;
