import { createSlice } from '@reduxjs/toolkit';

const unsavedChangesSlice = createSlice({
  name: 'unsavedChanges',
  initialState: { hasChanges: false },
  reducers: {
    setUnsavedChanges: (state, action) => {
      return { ...state, hasChanges: action.payload };
    },
    clearUnsavedChanges: (state) => {
      return { ...state, hasChanges: false };
    },
  },
});

export const { setUnsavedChanges, clearUnsavedChanges } = unsavedChangesSlice.actions;

export default unsavedChangesSlice;
