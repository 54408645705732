import React, {
  useState, useEffect, useContext, useMemo,
  useCallback,
} from 'react';
import MapGL, {
  FullscreenControl, NavigationControl, GeolocateControl, MapContext,
} from '@urbica/react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import bbox from '@turf/bbox';
import { Box, Button } from '@mui/material';
import isEqual from 'lodash.isequal';
import { usePrevious, useScrollBlock, useToggle } from '@/hooks';
import useTheme from '@/hooks/useTheme';
import { createWKTFromBounds } from '@/utils/geoutils';

const SetViewportProvider = React.createContext([{}, () => {
  console.error('This call was made outside of the provider');
}]);
export const useViewportProvider = () => useContext(SetViewportProvider);

const useCompare = (val) => {
  const prevVal = usePrevious(val);
  return !isEqual(prevVal, val);
};

const DEFAULT_LATITUDE = 40.742306;
const DEFAULT_LONGITUDE = -74.003494;

export const MapProvider = ({ defaultZoom = 4, children, features }) => {
  const initialViewport = {
    latitude: DEFAULT_LATITUDE,
    longitude: DEFAULT_LONGITUDE,
    zoom: defaultZoom,
  };

  if (features && features.features && features.features.length > 0) {
    // Find the first point feature and use its coordinates
    const firstFeature = features.features.find((feature) => feature.geometry.type === 'Point');

    if (firstFeature) {
      const [longitude, latitude] = firstFeature.geometry.coordinates;
      initialViewport.latitude = latitude;
      initialViewport.longitude = longitude;
    }
  }

  const viewportGetSet = useState(initialViewport);

  return (
    <SetViewportProvider.Provider value={viewportGetSet}>{children}</SetViewportProvider.Provider>
  );
};

export const MapDisplay = ({
  style = { width: '100%', flex: 1 },
  children = [],
  latitude,
  longitude,
  features,
  isFetching,
  followNewFeatures = false,
  onLocationChange,
  ...props
}) => {
  const { isDarkMode } = useTheme();
  const [viewport, setViewport] = useViewportProvider();
  const [map, setMap] = useState();
  const isInitToggle = useToggle();
  const [blockScroll, allowScroll] = useScrollBlock();
  const featuresChanged = useCompare(features);
  const isFirstMoveToggle = useToggle();
  const showRedrawButtonToggle = useToggle();

  const canShowMapControls = true;

  const mapStyle = useMemo(
    () => (isDarkMode
      ? 'mapbox://styles/mapbox/dark-v11'
      : 'mapbox://styles/mapbox/light-v11'),
    [isDarkMode],
  );

  const centerMap = useCallback(() => {
    let bounds;
    try {
      bounds = bbox(features);
    } catch (e) {
      console.error(e);
    }
    if (parseFloat(bounds?.[0]) !== Infinity) {
      try {
        map.fitBounds(bounds, { padding: 40 });
      } catch (error) {
        // Case: Map canvas is tiny
        map.fitBounds(bounds);
      }
    }
  }, [features, map]);

  // Center map when features change
  useEffect(() => {
    if (map && features && !isFetching && followNewFeatures && featuresChanged) {
      centerMap();
    }
  }, [centerMap, features, featuresChanged, followNewFeatures, isFetching, map]);

  // Center map on initial load
  useEffect(() => {
    if (map && !isFetching && !isInitToggle.value) {
      centerMap();
      isInitToggle.on();
    }
  }, [centerMap, isFetching, isInitToggle, map]);

  const handleHoverEnter = () => {
    blockScroll();
  };

  const handleHoverLeave = () => {
    if (!document.fullscreenElement) {
      allowScroll();
    }
  };

  const handleMapLoad = () => {
    const attachListenersToFullscreenButtons = () => {
      const fullscreenButtons = document.querySelectorAll('.mapboxgl-ctrl-fullscreen');
      fullscreenButtons.forEach((button) => {
        button.removeEventListener('mouseenter', handleHoverEnter);
        button.removeEventListener('mouseleave', handleHoverLeave);
        button.addEventListener('mouseenter', handleHoverEnter);
        button.addEventListener('mouseleave', handleHoverLeave);
      });
    };

    const observer = new MutationObserver(() => {
      attachListenersToFullscreenButtons();
    });

    observer.observe(document.body, { childList: true, subtree: true });

    attachListenersToFullscreenButtons();

    return () => {
      observer.disconnect();
    };
  };

  const handleSearchOnThisArea = () => {
    if (map && onLocationChange) {
      const bounds = map.getBounds();
      const wkt = createWKTFromBounds(bounds);
      const nextLocation = {
        value: wkt,
      };
      onLocationChange(nextLocation);
    }
  };

  const handleMoveEnd = () => {
    if (isFirstMoveToggle.value) {
      showRedrawButtonToggle.on();
    } else {
      isFirstMoveToggle.on();
    }
  };

  return (
    <MapGL
      {...props}
      style={style}
      mapStyle={mapStyle}
      accessToken={import.meta.env.VITE_MAPBOX_KEY}
      latitude={latitude || viewport.latitude}
      longitude={longitude || viewport.longitude}
      zoom={viewport.zoom}
      attributionControl={false}
      onViewportChange={setViewport}
      viewportChangeMethod="flyTo"
      onLoad={handleMapLoad}
      onMoveend={handleMoveEnd}
    >
      {children}
      <MapContext.Consumer>
        {(_map) => {
          // Forgive me lord
          if (_map !== map) { setMap(_map); }
        }}
      </MapContext.Consumer>
      {canShowMapControls && (
        <>
          <NavigationControl showCompass showZoom position="top-right" />
          <FullscreenControl position="top-right" />
          <GeolocateControl position="bottom-right" />
        </>
      )}
      {!!onLocationChange && showRedrawButtonToggle.value && (
        <Box display="flex" justifyContent="center" padding="55px">
          {/* TODO: validate permissions (waiting for PR review) */}
          <Button
            variant="contained"
            color="muted"
            size="small"
            onClick={handleSearchOnThisArea}
          >
            Redraw heatmap here
          </Button>
        </Box>
      )}
    </MapGL>
  );
};

export default (props) => <MapProvider {...props}><MapDisplay {...props} /></MapProvider>;
