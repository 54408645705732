import { useEffect, useRef } from 'react';

const useBatchedRequests = ({ callback, onDone }) => {
  const argsBitmapRef = useRef({});
  const timeoutRef = useRef(null);

  const call = () => {
    const args = Object.keys(argsBitmapRef.current);
    argsBitmapRef.current = {};

    if (args.length) {
      callback(args).then(onDone);
    }
  };

  const queueArg = (arg) => {
    if (arg !== undefined && arg !== null) {
      const prevArgsBitmap = argsBitmapRef.current;

      argsBitmapRef.current = {
        ...prevArgsBitmap,
        [arg]: 1,
      };

      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(call, 200);
    }
  };

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  return {
    queueArg,
  };
};

export default useBatchedRequests;
