import { urnsToDisplayName } from '@qloo/categories';
import clsx from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';
import { IconButton } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Breadcrumb from '@/components/Breadcrumb';
import { paramNames } from '@/constants';
import { useDashboardActionsContext } from '@/screens/Dashboard/DashboardActionsProvider';
import { affinityColumn, nameColumn, rankColumn } from '@/screens/Dashboard/DashboardPanel/helpers/getColumns';
import { useGetInsightQuery } from '@/store/apis/insightsApi';
import serializeInsightsParams from '@/utils/serializeInsightsParams';
import SelectedCategoryContentTags from './SelectedCategoryContentTags';
import TableWithTitle from '../TableWithTitle';
import SelectedCategorySubcategoryDialog from './SelectedCategorySubCategoryDialog';
import styles from '../PanelBodyExplore.module.scss';

const TableTitles = {
  ENTITIES: 'Entities',
  TAGS: 'Tags',
};

const SelectedCategoryContent = ({
  selectedCategory,
  handleSelectedCategory,
  baseParams,
  updateHasMoreExplorePages,
  pagination,
  panelId,
  exploreControls,
  panelSettings,
  panel,
  selectedSubCategories,
  categorySubCategories,
  handleSubCategoryChange,
  fixedSubcategory,
  handleResetSubcategories,
}) => {
  const [selectedData, setSelectedData] = useState();
  const dashboardActions = useDashboardActionsContext();

  const highlightedSelectedCategory = useMemo(() => {
    if (!selectedSubCategories) {
      return undefined;
    }
    const keysWithIncludeValues = Object.keys(selectedSubCategories)
      .filter((key) => selectedSubCategories[key] === 'include');

    return keysWithIncludeValues.length > 0 ? keysWithIncludeValues.join(';') : undefined;
  }, [selectedSubCategories]);

  useEffect(() => {
    setSelectedData(exploreControls?.specificData || null);
  }, [exploreControls?.specificData]);

  const serializedInsightsParams = useMemo(() => {
    let tempParams = {
      [paramNames.filterType]: selectedCategory,
      offset: pagination[paramNames.offset],
      take: pagination[paramNames.take] + 1,
    };

    if (highlightedSelectedCategory) {
      tempParams = {
        ...tempParams,
        [paramNames.filterTags]: { [highlightedSelectedCategory]: 'include' },
      };
    }

    return serializeInsightsParams(baseParams, tempParams);
  }, [baseParams, selectedCategory, pagination, highlightedSelectedCategory]);

  const {
    data: entitiesResult,
    isLoading: isEntitiesLoading,
    error: insightsError,
  } = useGetInsightQuery(serializedInsightsParams, {
    skip: selectedData === 'TAGS',
  });

  const entities = useMemo(
    () => (insightsError ? [] : entitiesResult?.results?.entities.slice(0, pagination[paramNames.take])),
    [entitiesResult?.results?.entities, insightsError, pagination],
  );

  const entitiesCount = entitiesResult?.results?.entities?.length || 0;

  useEffect(() => {
    updateHasMoreExplorePages(
      entitiesCount > pagination[paramNames.take], // || tagsCount > pagination[paramNames.take],
    );
  }, [
    entitiesCount,
    pagination,
    updateHasMoreExplorePages,
  ]);

  const handleChangePanelSettings = (nextExploreControls) => {
    dashboardActions.changePanelSettings({
      panelId,
      nextSettings: {
        ...panelSettings,
        exploreControls: {
          ...exploreControls, ...nextExploreControls,
        },
      },
    });
  };

  const handleSelectSpecificData = (type) => () => {
    setSelectedData(type);
    handleChangePanelSettings({ specificData: type });
  };

  const isEmptyBaseQuery = useMemo(
    () => Object.keys(baseParams).length === 0
      || Object.values(baseParams).every(
        (value) => (Array.isArray(value) && value.length === 0) || !value,
      ),
    [baseParams],
  );

  const getCurrentTags = () => {
    const subCategoriesData = categorySubCategories?.results?.tags;
    if (!subCategoriesData || subCategoriesData.length === 0 || !highlightedSelectedCategory) return [];

    const currentSubCategories = highlightedSelectedCategory.split(';');

    return subCategoriesData.filter((item) => currentSubCategories.includes(item.id))
      .map((option) => ({
        id: option.id,
        name: option.name,
      }));
  };

  const getSubCategoryName = () => {
    const subCategoriesData = categorySubCategories?.results?.tags;
    if (!subCategoriesData || subCategoriesData.length === 0 || !fixedSubcategory) return '';

    const { name } = subCategoriesData.find((item) => fixedSubcategory === item.id);

    return ` / ${name}`;
  };

  return (
    <div className={styles.content}>
      <div className={styles.header}>
        <span className={styles.name}>
          <IconButton
            onClick={() => {
              handleSelectedCategory(null)();
              updateHasMoreExplorePages(false);
              handleChangePanelSettings({ selectedCategory: null });
              handleResetSubcategories();
            }}
          >
            <ChevronLeftIcon />
          </IconButton>
          {urnsToDisplayName(selectedCategory)}
          {fixedSubcategory && fixedSubcategory !== selectedCategory && getSubCategoryName()}
        </span>
        <Breadcrumb
          items={[
            {
              label: 'Categories',
              onClick: () => {
                handleSelectedCategory(null)();
                updateHasMoreExplorePages(false);
                handleChangePanelSettings({ selectedCategory: null });
                handleResetSubcategories();
              },
            },
            !highlightedSelectedCategory && {
              label: (
                <SelectedCategorySubcategoryDialog
                  handleResetSubcategories={handleResetSubcategories}
                  selectedCategory={selectedCategory}
                  handleSubCategoryChange={handleSubCategoryChange}
                  selectedSubCategories={selectedSubCategories}
                  buttonLabel={urnsToDisplayName(selectedCategory)}
                />
              ),
            },
            !!highlightedSelectedCategory && {
              label: urnsToDisplayName(selectedCategory),
              ...((!!selectedData || !!highlightedSelectedCategory) && {
                onClick: () => {
                  if (highlightedSelectedCategory) {
                    handleSelectedCategory(selectedCategory)();
                    handleChangePanelSettings({ selectedCategory });
                    handleResetSubcategories();
                  }
                  return handleChangePanelSettings({ specificData: null });
                },
              }),
            },
            !selectedData && !!highlightedSelectedCategory && {
              label: (
                <SelectedCategorySubcategoryDialog
                  handleResetSubcategories={handleResetSubcategories}
                  selectedCategory={selectedCategory}
                  handleSubCategoryChange={handleSubCategoryChange}
                  selectedSubCategories={selectedSubCategories}
                  buttonLabel={highlightedSelectedCategory
                    ? getCurrentTags().map((item) => item.name).join(', ')
                    : 'filter for sub categories'}
                />
              ),
            },
            !!selectedData && {
              label: highlightedSelectedCategory
                ? getCurrentTags().map((item) => item.name).join(', ')
                : '',
              onClick: () => {
                handleChangePanelSettings({ specificData: null });
              },
            },
            !!selectedData && { label: TableTitles[selectedData] },
          ].filter(Boolean)}
        />
      </div>

      <div className={clsx({ [styles.data]: true, [styles.singleData]: !!selectedData })}>
        {(!selectedData || selectedData === 'TAGS') && (
          <SelectedCategoryContentTags
            baseParams={baseParams}
            handleSelectSpecificData={handleSelectSpecificData}
            pagination={pagination}
            panel={panel}
            selectedCategory={selectedCategory}
            selectedData={selectedData}
          />
        )}
        {(!selectedData || selectedData === 'ENTITIES') && (
          <TableWithTitle
            isLoading={isEntitiesLoading}
            title="Entities"
            columns={[
              rankColumn,
              nameColumn,
              affinityColumn,
            ]}
            data={entities || []}
            onSelect={handleSelectSpecificData(selectedData === 'ENTITIES' ? null : 'ENTITIES')}
            isEmptyBaseQuery={isEmptyBaseQuery}
          />
        )}
      </div>
    </div>
  );
};

export default SelectedCategoryContent;
