import React, { useMemo, useState } from 'react';
import Typography from '@mui/material/Typography';
import { Box, InputAdornment } from '@mui/material';
import { Search, XCircle } from 'react-feather';
import FormControl from '@mui/material/FormControl';
import clsx from 'clsx';
import { styled } from '@mui/system';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import { urnsToDisplayName } from '@qloo/categories';
import Checkbox from '@mui/material/Checkbox';
import filterUniqueOptionsByHierarchy from '@/utils/filterUniqueOptionsByHierarchy';
import capitalize from '@/utils/capitalize';
import styles from './SearchSelect.module.scss';
import EnableDisableToggle from '../EnableDisableToggle';
import Skeleton from '../Skeleton';

const countDepth = (name, showCheckbox) => name.split(':').length - (showCheckbox ? 3 : 5);

const pathExceptName = (option) => `${option?.replace(/_/g, ' ').split(':').slice(2, -1).join(' > ')} > `;

const FormControlLabelStyled = styled(FormControlLabel)({
  marginBottom: '12px',
  marginLeft: 0,
  '& .MuiFormControlLabel-label': {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 'bold',
  },
});

const TextFieldStyled = styled(TextField)(() => ({
  '& .MuiInputBase-root': {
    fontSize: '16px',
    height: '40px',
    padding: '10px 12px',
    backgroundColor: 'hsl(var(--background-accent))',
    borderRadius: 'var(--border-radius)',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    display: 'none',
  },
  margin: '12px 0',
}));

const RenderOptions = ({
  options, onChange, showCheckbox, selectedData, isSearching,
}) => {
  const renderPathExceptName = (id) => {
    const path = pathExceptName(id);
    return path.length > 3 ? path : '';
  };

  const filteredOptions = filterUniqueOptionsByHierarchy(options);

  return (
    <>
      {filteredOptions.map((option) => (
        <div key={option.id} className={styles[`option${countDepth(option.id, showCheckbox)}`]}>
          <FormControlLabelStyled
            control={(
              showCheckbox ? (
                <Checkbox
                  checked={!!selectedData.includes(option.id)}
                  onChange={(_, checked) => onChange(option.id, checked)}
                  size="small"
                />
              ) : (
                <EnableDisableToggle
                  name={option.id}
                  onChange={onChange}
                  className={clsx(styles, styles.toggle)}
                  value={
                    !selectedData[option.id] ? null : selectedData[option.id] === 'include'
                  }
                />
              )
            )}
            title={option.id}
            label={(
              <>
                {isSearching && (
                  <span className={styles.pathExceptName}>
                    {renderPathExceptName(option.id)}
                  </span>
                )}
                {option.name}
              </>
            )}
          />
          {Object.values(option.children).length > 0 && (
            <RenderOptions
              options={Object.values(option.children)}
              onChange={onChange}
              selectedData={selectedData}
              showCheckbox={showCheckbox}
              isSearching={isSearching}
            />
          )}
        </div>
      ))}
    </>
  );
};

const ListOptionsSkeleton = () => (
  <div>
    {[...Array(10)].map((_, index) => (
      <div key={index} className={styles.skeleton}>
        <Skeleton width={45} height={22} />
        <Skeleton width={300} height={22} />
      </div>
    ))}
  </div>
);

const NoResultsMessage = () => (
  <div className={styles.option0}>
    <Typography variant="body3" fontWeight="semibold">No results</Typography>
  </div>
);

const groupOptionsByHierarchy = (options, showCheckbox) => {
  const hierarchy = {};

  options.forEach((option) => {
    let currentLevel = hierarchy;

    if (!option.id.includes('urn')) {
      currentLevel[option] = {
        ...option,
        id: option.id,
        name: urnsToDisplayName(option.name),
        children: [],
      };
      return;
    }

    const parts = option.id.split(':').slice(showCheckbox ? 2 : 4);

    parts.forEach((part, index) => {
      const key = parts.slice(0, index + 1).join(':');

      if (!currentLevel[key]) {
        currentLevel[key] = {
          ...option,
          id: key,
          name: urnsToDisplayName(key),
          children: [],
        };
      }

      if (index === parts.length - 1) {
        currentLevel[key] = { ...option, children: currentLevel[key].children };
      }

      currentLevel = currentLevel[key].children;
    });
  });

  return Object.values(hierarchy).map((item) => ({
    ...item,
    name: capitalize(Array.isArray(item.name) ? item.name[0] : item.name),
    children: Object.values(item?.children).map((itemChildren) => {
      return {
        ...itemChildren,
        name: capitalize(Array.isArray(itemChildren.name) ? itemChildren.name[0] : itemChildren.name),
      };
    }),
  })).sort((a, b) => a.name.localeCompare(b.name));
};

const SearchSelect = ({
  search,
  onChange,
  onChangeSearch,
  onClear,
  title,
  placeholder,
  ariaLabel,
  name,
  isFetching,
  data,
  selectedData,
  showCheckbox,
  resetPadding,
}) => {
  const [showOnlySelected, setShowOnlySelected] = useState(null);

  const toggleShowOnlySelected = () => {
    setShowOnlySelected(!showOnlySelected);
  };

  const selectedOptions = useMemo(() => {
    if (showCheckbox) {
      return selectedData.map((urn) => {
        const urnSplit = urn.split(':');
        return {
          id: urn, name: capitalize(urnSplit[urnSplit.length - 1].replace(/_/g, ' ')),
        };
      });
    }
    return Object.keys(selectedData).map((urn) => {
      const urnSplit = urn.split(':');
      return {
        id: urn, name: capitalize(urnSplit[urnSplit.length - 1].replace(/_/g, ' ')),
      };
    });
  }, [selectedData, showCheckbox]);

  const groupedOptions = useMemo(() => {
    if (showOnlySelected) {
      return groupOptionsByHierarchy(selectedOptions, showCheckbox);
    }
    const options = data || [];
    if (search) {
      return groupOptionsByHierarchy(options, showCheckbox);
    }
    return [...groupOptionsByHierarchy([...options, ...selectedOptions
      .filter((selectedOption) => !options.find((option) => option.id === selectedOption.id))], showCheckbox)];
  }, [selectedOptions, data, showOnlySelected, showCheckbox, search]);

  return (
    (
      <div className={clsx({ [styles.container]: true, [styles.resetPadding]: resetPadding })}>
        {title && (
          <Typography variant="caption" className={styles.name}>
            {title}
          </Typography>
        )}
        <TextFieldStyled
          placeholder={placeholder}
          variant="outlined"
          fullWidth
          onChange={(e) => {
            onChangeSearch(e.target.value);
            if (e.target.value === '') {
              onClear();
            }
          }}
          value={search}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search size={16} className={styles.searchIcon} />
              </InputAdornment>
            ),
            endAdornment: !!search && (
              <InputAdornment position="end">
                <XCircle size={16} className={styles.clearIcon} onClick={onClear} />
              </InputAdornment>
            ),
          }}
        />
        {selectedOptions.length > 0 && !search && data?.length > 0 && (
          (
            <Box onClick={toggleShowOnlySelected} className={styles.showModeToggleButton}>
              {showOnlySelected ? 'Show all' : 'Show only selected'}
            </Box>
          )
        )}
        <FormControl
          name={name}
          aria-label={ariaLabel}
          className={styles.formControl}
        >
          {isFetching && <ListOptionsSkeleton />}
          {!isFetching && groupedOptions.length > 0 && (
            <RenderOptions
              onChange={onChange}
              options={groupedOptions}
              showCheckbox={showCheckbox}
              selectedData={selectedData}
              isSearching={!!search}
            />
          )}
          {!isFetching && search && data.length === 0 && <NoResultsMessage />}
        </FormControl>
      </div>
    )
  );
};

export default SearchSelect;
