import {
  Box, Button, capitalize, Card, CardActionArea, Typography,
} from '@mui/material';
import { formatEntityCategories } from '@/utils/tagCategories';
import getColorsByTypes from '@/utils/getColorsByTypes';
import EntityImage from '../EntityImage';
import OmnisearchResultsTopAdditionalElement from './OmnisearchResultsTopAdditionalElement';

import styles from './Omnisearch.module.scss';

const OmnisearchResultsTopEntity = ({ entity, selected, ...props }) => {
  const tags = entity.tags || [];
  const types = entity.types || [];

  const description = entity?.properties.description
    || '';

  const colors = getColorsByTypes(types);

  return (
    <Card sx={{ boxShadow: 0 }}>
      <CardActionArea {...props}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="start"
          gap={2}
          p={2}
          lineHeight={1.5}
          bgcolor={selected && colors.contrastText}
        >
          <Box display="flex" width="100%">
            <div className={styles.entityImageContainer}>
              <EntityImage
                className={styles.entityImage}
                entity={entity}
                placeholder={entity.name}
              />
            </div>
            <Box flex={1} display="flex" flexDirection="column" pl={1.5}>
              <Typography fontWeight="bold">
                {entity.name}
              </Typography>
              <Typography sx={{ color: 'muted.contrastText' }}>
                {entity.disambiguation || ' '}
              </Typography>
              <Typography
                fontWeight="bold"
                textTransform="capitalize"
                color={colors.main}
              >
                {formatEntityCategories(entity)}
              </Typography>
            </Box>
          </Box>
          {description && <OmnisearchResultsTopAdditionalElement title="Description" content={description} />}
          {tags && tags.length > 0 && (
            <OmnisearchResultsTopAdditionalElement
              title="Tags"
              content={tags.map((tag) => {
                // special handling for book tags, data team is aware of this bug
                // in the meantime, a bit of sanitation for this edge case
                const tagNameParts = (tag.name || '').split(':');
                const tagName = tagNameParts[tagNameParts.length - 1];
                return capitalize(tagName.replace(/_/g, ' '));
              }).join(', ')}
            />
          )}
          <Button color="primary" variant="text" size="small" bold font="Inter" sx={{ mt: 1.5 }}>
            {selected ? 'DESELECT' : 'SELECT'}
          </Button>
        </Box>
      </CardActionArea>
    </Card>

  );
};

export default OmnisearchResultsTopEntity;
