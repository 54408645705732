import React from 'react';
import styles from './Omnisearch.module.scss';

const OmnisearchResultsTopAdditionalElement = ({ title, content }) => (
  <div className={styles.additionalInfoElementContainer}>
    <div className={styles.additionalInfoElementTitle}>{title}</div>
    <div className={styles.additionalInfoElementContent}>{content}</div>
  </div>
);

export default OmnisearchResultsTopAdditionalElement;
