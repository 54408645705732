import React, { memo } from 'react';
import bbox from '@turf/bbox';
// import { toMercator } from '@turf/projection';
import geojson2svg from 'geojson2svg';
import { MapPin } from 'react-feather';
import { WktToGeoJson } from '@/utils/geoutils';

const STYLE_SVG = {
  width: 'auto',
  verticalAlign: 'middle',
  opacity: 0.7,
};

export const PointIcon = ({ size = 25 }) => (
  <MapPin size={size} />
);

const GeoIconView = ({ wkt, geojson, size = 25 }) => {
  if (!wkt && !geojson) return null;

  const useGeoJson = geojson || WktToGeoJson(wkt);

  if (useGeoJson?.type === 'Point') {
    return <PointIcon size={size} />;
  }

  try {
    // Thanks https://observablehq.com/@jrajav/rendering-well-known-text-as-svgs
    // const geojsonMercator = toMercator(geojson);
    const [left, bottom, right, top] = bbox(useGeoJson);

    // Calculate the bounding box dimensions
    const bboxWidth = right - left;
    const bboxHeight = top - bottom;

    // Calculate the center of the bounding box
    const centerX = (left + right) / 2;
    const centerY = (top + bottom) / 2;

    // Calculate the aspect ratio of the bounding box
    const bboxAspectRatio = bboxWidth / bboxHeight;

    // Determine the aspect ratio of the SVG canvas
    const svgAspectRatio = size / size;

    // Adjust the mapExtent to center the GeoJSON
    let adjustedWidth; let
      adjustedHeight;
    if (svgAspectRatio > bboxAspectRatio) {
      // SVG is wider than the bounding box
      adjustedHeight = bboxHeight;
      adjustedWidth = bboxHeight * svgAspectRatio;
    } else {
      // SVG is taller than the bounding box
      adjustedWidth = bboxWidth;
      adjustedHeight = bboxWidth / svgAspectRatio;
    }

    const mapExtent = {
      left: centerX - adjustedWidth / 2,
      bottom: centerY - adjustedHeight / 2,
      right: centerX + adjustedWidth / 2,
      top: centerY + adjustedHeight / 2,
    };

    const iconMaker = geojson2svg({
      viewportSize: {
        width: size,
        height: size,
      },
      mapExtent,
    });

    const svg = iconMaker.convert(useGeoJson);
    return (
      <svg
        style={{
          ...STYLE_SVG,
          height: size,
        }}
        width="100%"
        viewBox={`0 0 ${size} ${size}`}
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: svg }}
        />
      </svg>
    );
  } catch (e) {
    console.error(useGeoJson, 'Icon error:', e);
    return null;
  }
};

export default memo(GeoIconView);
