import ReactMarkdown from 'react-markdown';
import tryParseJson from '@/utils/tryParseJson';
import personaForm from '../shared/personaForm';
import personaInsights from '../shared/personaInsights';
import getPersonaResultMarkdown from '../shared/getPersonaResultMarkdownRealEstate';

const schema = `{
    "strict": True,
    "required": [
        "who_we_are",
        "what_drives_us",
        "pain_points",
        "goals_and_needs",
        "day_in_the_life",
        "apartment_ideas",
        "ideal_amenities",
    ],
    "additionalProperties": False,
    "properties": {
        "who_we_are": {
            "type": "string",
            "$comment": "Write a detailed description of the persona's background, including their profession, age, interests, and daily habits. Highlight their passions, key personality traits, and how they interact with their living space. Provide specific brand affinities or lifestyle cues that define them."
        },
        "what_drives_us": {
            "type": "string",
            "$comment": "Describe the core motivations and driving factors for the persona. Explain what inspires them, what they value in their environment, and how they connect with their community. Highlight emotional and functional needs driving their decisions."
        },
        "pain_points": {
            "type": "string",
            "$comment": "List the main challenges or frustrations the persona experiences in their living environment. These should be specific and tied to their lifestyle, habits, and expectations from their apartment or community space."
        },
        "goals_and_needs": {
            "type": "array",
            "$comment": "Listed descriptions of goals and needs",
            "items": {
                "type": "string",
                "description": "string",
                "$comment": "Outline the persona's primary goals and needs related to their living space and community. Include both practical and emotional needs, focusing on what would enhance their overall satisfaction and experience."
            }
            // "type": "string",
            // "$comment": "Outline the persona's primary goals and needs related to their living space and community. Include both practical and emotional needs, focusing on what would enhance their overall satisfaction and experience."
        },
        "day_in_the_life": {
            "type": "string",
            "$comment": "Write a narrative describing a typical day in the persona's life. Highlight key moments, habits, and interactions they have with their living space and community. Use specific examples of activities and brand interactions where applicable."
        },
        "apartment_ideas": {
            "type": "array",
            "$comment": "Descriptions of the ideal amenities for the persona",
            "items": {
                "type": "string",
                "description": "string",
                "$comment": "Propose three tailored apartment or community ideas designed to meet the persona's needs and preferences. Each idea should include a clear motivation explaining how it addresses their lifestyle and enhances their experience."
            }
        }
    }
  }`;
const mapEntity = (entity) => `Entity: ${entity.name}, Subject: ${entity.subtype}, Score: ${entity.query.affinity}`;

const mapTag = (entity) => `Tag Genre: ${entity.name}, Subject: ${entity.subtype}, Score: ${entity.query.affinity}`;

const personas = {
  name: 'Real Estate Focused Persona',
  description: 'Real Estate focused personas for property teams',
  form: personaForm,
  insights: personaInsights,
  render: ({ result }) => {
    const markdown = getPersonaResultMarkdown(tryParseJson(result.slice(7, -3) || '[]'));
    return (
      <ReactMarkdown>
        {markdown}
      </ReactMarkdown>
    );
  },
  runner: async ({
    entities, fetchGpt, tags, userData,
  }) => {
    const { personaCount } = userData;
    const prompt = `
    As a persona developer with the ability to create ${personaCount} goal-directed personas that cut straight to the nitty-gritty, develop a persona report that details the top three personas that are surfaced from the entities and tags intelligence provided:
    **Input Data**:
      - Tags: ${tags.map(mapTag).join(' ')}
      - Entities: ${entities.map(mapEntity).join(' ')}
    2. **Persona Instructions**:
    - Each persona must be **entirely based** on the provided tags, entities, and affinity scores. Affinity measures the co-occurrence likelihood between an entity and the location, ranging from 0-1. Use **clear, engaging language** to ensure alignment with the data. Connect entities and tags to their affinity scores explaining why each is relevant to the persona's traits and lifestyle.
    - These personas should focus on their motivations or what makes them thrive, what are the little things that keep them inspired, how they feel from the inside and the outside, what their character traits are, what makes them tick, what makes them eccentric, pain points or frustrations that could manifest in an apartment setting given their interests, and their overall needs and goals that are observed from the brands as it relates to apartment living.\
     Enrich each persona with brands and places from the file to elevate the insights that guided their creation. The goal for an apartment owner is to maximize the resident experience at the apartment building. This requires in depth understanding of what your residents want and these personas are being developed to help the attraction and retention of prospects and incumbents that align with these personas. Present these personas in a prosaic form that’s captivating to the reviewer of this persona report.
    3. **Sections**:
    - Include a “day in the life” section for these different personas that is guided by the brands in the attached file.\
     Integrate some meaningful and captivating micro-moments in the as this will help bring the personas to life in this section.\
     Also weave in some fictional but realistic quotes from each persona. For each of the personas,\
     Include three ideas that an apartment owner could consider implementing to enhance the experience of each of these persona types\
     and what the motivations for these ideas are for each persona with guidance only from the attached file.\
    - Conclude with a recommendation that blends the persona interests and highlights 3 ideas that will enhance experiences for these personas.\
     Highlight how these different personas might interact within the same apartment community and guide how a sense of community can be developed and\
     curated with the insights from the attached file.\
    - Generate some ideas around partnerships or incentive opportunities that an owner can explore to entice and motivate residents.\
    - Return all personas in a **JSON array** structured according to this schema: ${JSON.stringify(schema)}
    `;

    try {
      const gptResult = await fetchGpt({ prompt });
      return gptResult;
    } catch (e) {
      return 'Error';
    }
  },
};

export default personas;
