import { useState } from 'react';
import { Autocomplete, Box } from '@mui/material';
import useEntitiesSearch from '@/hooks/useEntitiesSearch';
import InputV2 from '../Input/InputV2';
import EntityItem from '../EntityItem';
import EntityChip from './EntityChip';

const getNoOptionsText = (search, entityResults) => {
  if (!search) return 'Start typing to search';
  if (entityResults && entityResults.length) return 'Searching...';
  return 'No results found';
};

const EntitiesSearch = ({ value, filterType, onChange }) => {
  const [search, setSearch] = useState('');

  const {
    isLoading, isFetching, isDebouncing, results: entityResults,
  } = useEntitiesSearch({ search, types: [filterType] });

  const handleChange = (e, newValue) => {
    const nextValue = newValue.map((v) => ({
      entity_id: v.entity_id,
      name: v.name,
      types: v.types,
    }));

    onChange(nextValue);
  };

  const handleDeleteByIndex = (index) => () => {
    const nextValue = [...value];
    nextValue.splice(index, 1);
    onChange(nextValue);
  };

  const noOptionsText = getNoOptionsText(search, entityResults);

  return (
    <Box gap={2} display="flex" flexDirection="column">
      <Autocomplete
        multiple
        value={value || undefined}
        onChange={handleChange}
        id="entities-search"
        options={search.length ? entityResults || [] : []}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, entity) => option.entity_id === entity.entity_id}
        loading={isLoading || isFetching || isDebouncing}
        loadingText="Searching..."
        noOptionsText={noOptionsText}
        onClose={() => setSearch('')}
        filterOptions={(x) => x}
        renderInput={(params) => (
          <InputV2
            {...params}
            variant="outlined"
            label=""
            placeholder="Filter by Entities"
            value={search}
            onChange={setSearch}
            sx={{ marginTop: 1 }}
          />
        )}
        renderOption={(props, option, state) => {
          return (
            <Box padding="6px 16px">
              <EntityItem entity={option} selected={state.selected} {...props} />
            </Box>
          );
        }}
        renderTags={() => {}} // Hide the tags
      />
      <Box display="flex" gap={1}>
        {!!value && value.map((entity, i) => (
          <EntityChip entity={entity} onDelete={handleDeleteByIndex(i)} />
        ))}
      </Box>
    </Box>
  );
};

export default EntitiesSearch;
