import React from 'react';
import {
  IconButton, ListItemIcon, ListItemText, Menu, MenuItem,
} from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import useHasPermission from '@v1/hooks/useHasPermission';
import { api, filterTypes } from '@/constants';
import isTableFilterType from '@/utils/isTableFilterType';
import { useActiveDashboard, useUserDashboardPermissions } from '@/hooks';
import { useDashboardActionsContext } from '../../DashboardActionsProvider';
import MenuItemExportData from './MenuItemExportData';
import { noInsightPanels } from '../helpers/constants';
import MenuItemRename from './MenuItemRename';

const PanelContextMenu = ({
  panelId, panelTitle, serializedInsightsParams, filterType,
}) => {
  const dashboard = useActiveDashboard();
  const { hasRenamePermission } = useUserDashboardPermissions(dashboard.id);
  const dashboardActions = useDashboardActionsContext();
  const hasExportPermission = useHasPermission('feature.dashboard.panel.export');

  const menuPopupState = usePopupState({
    variant: 'popper',
    popupId: 'menuPopupState',
  });

  const handlePanelDelete = () => {
    dashboardActions.deletePanel(panelId);
  };

  const handlePanelClone = () => {
    dashboardActions.clonePanel(panelId);
  };

  const handleOpenInRequestBrowser = () => {
    const requestUrl = `${api.server}/v2/insights?${serializedInsightsParams}`;
    window.open(
      `/#/requests?requests=${encodeURIComponent(requestUrl)}`,
      '_blank',
    );
  };

  const isInsightPanel = !noInsightPanels.includes(filterType);
  const isTable = isTableFilterType(filterType);
  const isHeatmap = filterType === filterTypes.heatmap;

  return (
    <>
      <IconButton aria-label="panel-menu" {...bindTrigger(menuPopupState)}>
        <MoreHorizIcon />
      </IconButton>
      <Menu {...bindMenu(menuPopupState)}>
        <MenuItemRename panelId={panelId} panelTitle={panelTitle} disabled={!hasRenamePermission} />
        <MenuItem onClick={handlePanelClone}>
          <ListItemIcon><FileCopyOutlinedIcon /></ListItemIcon>
          <ListItemText>Clone Panel</ListItemText>
        </MenuItem>
        {isInsightPanel && (
          <MenuItem onClick={handleOpenInRequestBrowser}>
            <ListItemIcon><OpenInNewIcon /></ListItemIcon>
            <ListItemText>Open in request browser</ListItemText>
          </MenuItem>
        )}
        <MenuItem onClick={handlePanelDelete}>
          <ListItemIcon><DeleteOutlineIcon /></ListItemIcon>
          <ListItemText>Delete Panel</ListItemText>
        </MenuItem>
        {((isTable || isHeatmap) && hasExportPermission) && (
          <MenuItemExportData
            panelId={panelId}
            serializedInsightsParams={serializedInsightsParams}
            onDownloaded={menuPopupState.close}
          />
        )}
      </Menu>
    </>
  );
};

export default PanelContextMenu;
