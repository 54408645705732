import dot from 'dot-object';

const properties = [
  { key: 'who_we_are', label: 'Who we are', placeholder: 'No overview available' },
  { key: 'what_drives_us', label: 'What drives us', placeholder: 'No data available' },
  { key: 'pain_points', label: 'Pain points', placeholder: 'No data available' },
  {
    key: 'goals_and_needs', format: (value) => (value?.length ? value.map((v) => `* ${v}`).join('\n') : null), label: 'Goals and needs', placeholder: 'No data available',
  },
  { key: 'day_in_the_life', label: 'Day in the life', placeholder: 'No data available' },
  {
    key: 'apartment_ideas', format: (value) => (value?.length ? value.map((v) => `* ${v}`).join('\n') : null), label: 'Apartment ideas', placeholder: 'No data available',
  },
];

const mapProperty = (sectionPrefix, persona) => (property) => {
  const value = dot.pick(property.key, persona);
  const formattedValue = property.format ? property.format(value) : value;
  return `${sectionPrefix} ${property.label}\n${formattedValue || property.placeholder}`;
};

const getPersonaResultMarkdown = (personasResult) => personasResult.map((personaResult, i) => {
  const persona = personaResult?.properties || personaResult;
  return `# Persona ${i + 1}\n${properties.map((property) => {
    if (property.items) {
      return `## ${property.label}\n${property.items.map(mapProperty('###', persona)).join('\n')}`;
    }

    return mapProperty('##', persona)(property);
  }).join('\n')}`;
}).join('\n');

export default getPersonaResultMarkdown;
