const personaInsights = ({ userData }) => {
  const { personaCount } = userData;
  const baseTake = Math.max(Math.floor(40 / personaCount), 3); // Base `take` calculation

  // const take = Math.max(Math.floor(30 / personaCount), 3);
  return {
    entities: [
      { category: 'artist', params: { take: Math.floor(baseTake / 3) } },
      // { category: 'book', params: { take } },
      { category: 'brand', params: { take: baseTake } },
      { category: 'movie', params: { take: Math.floor(baseTake / 2) } },
      // { category: 'person', params: take5 } },
      { category: 'place', params: { take: baseTake } },
      { category: 'podcast', params: { take: Math.floor(baseTake / 3) } },
      { category: 'tv_show', params: { take: Math.floor(baseTake / 2) } },
      { category: 'destination', params: { take: Math.floor(baseTake / 3) } },
    ],
    tags: [
      { category: 'artist', params: { take: Math.floor(baseTake / 3) } },
      // { category: 'book', params: { take: Math.floor(baseTake / 3) } },
      { category: 'brand', params: { take: baseTake } },
      { category: 'movie', params: { take: Math.floor(baseTake / 2) } },
      // { category: 'person', params: { take } },
      { category: 'place', params: { take: baseTake } },
      { category: 'place', params: { 'filter.tags.types': 'urn:tag:good_for:place', take: 3 } },
      { category: 'podcast', params: { take: Math.floor(baseTake / 3) } },
      { category: 'tv_show', params: { take: Math.floor(baseTake / 2) } },
      { category: 'destination', params: { take: Math.floor(baseTake / 3) } },
      // { params: { 'filter.tags.types': 'urn:tag:audience:qloo', take } },
      // { category: 'brand', params: {'filter.tags.types': 'urn:tag:archetype:qloo', take} }
    ],
  };
};

export default personaInsights;
