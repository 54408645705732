import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useGetTagsQuery } from '@/store/apis/tagsApi';
import { mergeTags } from '@/store/slices/tagsSlice';
import useDebounce from './useDebounce';

const TAG_TYPES = ['urn:tag'];

const useTagsSearch = ({
  search, parentType, take = 15, debounce = 250,
}) => {
  const dispatch = useDispatch();
  const debouncedSearch = useDebounce(search, debounce);

  const { data, isLoading, isFetching } = useGetTagsQuery({
    query: debouncedSearch,
    take,
    parentType,
    tagTypes: TAG_TYPES,
  }, {
    skip: !debouncedSearch,
  });

  const isDebouncing = useMemo(() => debouncedSearch !== search, [debouncedSearch, search]);

  useEffect(() => {
    if (data?.results?.tags) {
      dispatch(mergeTags(data.results.tags));
    }
  }, [data, dispatch]);

  return {
    isLoading,
    isFetching,
    isDebouncing,
    results: data?.results?.tags,
  };
};

export default useTagsSearch;
