import { paramNames } from '@/constants';
import getDemographicsLabel from '@/utils/getDemographicsLabel';
import getParamsLabel from '@/utils/getParamsLabel';

const mapEntity = (entity) => `Entity: ${entity.name}, Subject: ${entity.subtype}, Score: ${entity.query.affinity}`;

const mapTag = (entity) => `Tag Genre: ${entity.name}, Subject: ${entity.subtype}, Score: ${entity.query.affinity}`;

const custom = {
  name: 'Custom',
  features: {
    categorySelect: true,
    interactive: false,
  },
  form: {
    $schema: 'http://json-schema.org/draft-07/schema#',
    type: 'object',
    properties: {
      instructions: {
        type: 'string',
        title: 'Question',
        description: "What would you like to know about this audience's taste?",
      },
    },
    required: ['instructions'],
  },
  insights: {
    entities: [
      { category: 'artist', params: { take: 3 } },
      // { category: 'book', params: { take } },
      { category: 'brand', params: { take: 5 } },
      { category: 'movie', params: { take: 4 } },
      // { category: 'person', params: take5 } },
      { category: 'place', params: { take: 5 } },
      { category: 'podcast', params: { take: 3 } },
      { category: 'tv_show', params: { take: 4 } },
      // { category: 'destination', params: { take: 3 } },
    ],
    tags: [
      { category: 'artist', params: { take: 3 } },
      // { category: 'book', params: { take: 3 } },
      { category: 'brand', params: { take: 5 } },
      { category: 'movie', params: { take: 4 } },
      // { category: 'person', params: { take } },
      { category: 'place', params: { take: 5 } },
      { category: 'podcast', params: { take: 3 } },
      { category: 'tv_show', params: { take: 4 } },
      // { category: 'destination', params: { take: 3 } },
      // { params: { 'filter.tags.types': 'urn:tag:audience:qloo', take } },
      // { category: 'brand', params: {'filter.tags.types': 'urn:tag:archetype:qloo', take} }
    ],
  },
  runner: async ({
    baseParams, entities, fetchGpt, tags, userData,
  }) => {
    const demographicsLabel = getDemographicsLabel(baseParams);
    const paramsLabel = getParamsLabel(baseParams);
    const prompt = `
      For people who are considered: ${paramsLabel}
      ${demographicsLabel}${baseParams[paramNames.signalLocation] ? `and live in ${baseParams[paramNames.signalLocation].label}` : ''}
      and might like ${entities.map(mapEntity).join(' ')}
      and might be interested in: ${tags.map(mapTag).join(' ')}.

      ${userData.instructions}`;

    try {
      const gptResult = await fetchGpt({ prompt });
      return gptResult;
    } catch (e) {
      return 'Error';
    }
  },
};

export default custom;
