const tagTypesBySelectedCategory = {
  'urn:entity:artist': [
    'urn:tag:artist:qloo',
    'urn:tag:audience:qloo',
    'urn:tag:complexity:qloo',
    'urn:tag:instrument:qloo',
    'urn:tag:music:qloo',
    'urn:tag:obscurity:qloo',
    'urn:tag:style:qloo',
    'urn:tag:theme:qloo',
  ],
  'urn:entity:brand': [
    'urn:tag:lifestyle:qloo',
    'urn:tag:personal_style:qloo',
    'urn:tag:aesthetic_property:qloo',
    'urn:tag:adjective:qloo',
    'urn:tag:emotional_tone:qloo',
    'urn:tag:keyword:qloo',
  ],
  'urn:entity:movie': [
    'urn:tag:archetype:qloo',
    'urn:tag:audience:qloo',
    'urn:tag:character:qloo',
    'urn:tag:keyword:qloo',
    'urn:tag:plot:qloo',
    'urn:tag:style:qloo',
    'urn:tag:subgenre:qloo',
  ],
  'urn:entity:place': [
    'urn:tag:ambience:qloo',
    'urn:tag:cuisine:qloo',
    'urn:tag:decor:qloo',
    'urn:tag:good_for:qloo',
    'urn:tag:setting:qloo',
  ],
  'urn:entity:podcast': [
    'urn:tag:archetype:qloo',
    'urn:tag:audience:qloo',
    'urn:tag:content:qloo',
    'urn:tag:keyword:qloo',
    'urn:tag:style:qloo',
    'urn:tag:subgenre:qloo',
    'urn:tag:theme:qloo',
  ],
  'urn:entity:tv_show': [
    'urn:tag:archetype:qloo',
    'urn:tag:audience:qloo',
    'urn:tag:character:qloo',
    'urn:tag:keyword:qloo',
    'urn:tag:plot:qloo',
    'urn:tag:style:qloo',
    'urn:tag:subgenre:qloo',
  ],
  'urn:entity:book': [
    'urn:tag:historical_context:qloo',
    'urn:tag:influence:qloo',
    'urn:tag:motif:qloo',
    'urn:tag:narrative:qloo',
    'urn:tag:subgenre:qloo',
    'urn:tag:target_audience:qloo',
    'urn:tag:theme:qloo',
  ],
  'urn:entity:destination': [
    'urn:tag:characteristic:qloo',
    'urn:tag:climate:qloo',
    'urn:tag:cuisine:qloo',
    'urn:tag:culture:qloo',
    'urn:tag:scenery:qloo',
    'urn:tag:shopping:qloo',
  ],
  'urn:entity:videogame': [
    'urn:tag:audience:qloo',
    'urn:tag:category:qloo',
    'urn:tag:content_rating:qloo',
    'urn:tag:gameplay_type:qloo',
    'urn:tag:player_mode:qloo',
    'urn:tag:subgenre:qloo',
  ],
  'urn:entity:person': [
    'urn:tag:category:qloo',
    'urn:tag:ethnicity:qloo',
    'urn:tag:language:qloo',
    'urn:tag:occupation:qloo',
    'urn:tag:personal_style:qloo',
    'urn:tag:residence:qloo',
  ],
};

export default tagTypesBySelectedCategory;
