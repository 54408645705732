import { useMemo } from 'react';
import { useCategoryPermUrns } from '@v1/lib/usePermissions';
import useEntitiesSearch from '@/hooks/useEntitiesSearch';
import useTagsSearch from '@/hooks/useTagsSearch';
import getOmnisearchResults from './getOmnisearchResults';

const coerceArray = (val) => (Array.isArray(val) ? val : val ? [val] : []);

const useOmnisearchResults = ({ search, category }) => {
  const validCategories = useCategoryPermUrns();
  const effectiveTypes = useMemo(() => (category ? [category] : coerceArray(validCategories)), [category]);

  const {
    isLoading: isLoadingEntities,
    isFetching: isFetchingEntities,
    isDebouncing: isDebouncingEntities,
    results: entityResults,
  } = useEntitiesSearch({ search, types: effectiveTypes, take: 9 });

  const {
    isLoading: isLoadingTags,
    isFetching: isFetchingTags,
    isDebouncing: isDebouncingTags,
    results: tagResults,
  } = useTagsSearch({ search, parentType: category || undefined, take: 20 });

  const { entities, tags, topResult } = useMemo(() => getOmnisearchResults({
    entityResults: entityResults || [],
    search,
    tagResults: tagResults || [],
  }), [entityResults, search, tagResults]);

  const isLoading = useMemo(() => (
    isLoadingTags || isFetchingTags || isDebouncingTags || isLoadingEntities || isFetchingEntities || isDebouncingEntities
  ), [isLoadingEntities, isFetchingEntities, isDebouncingEntities, isLoadingTags, isFetchingTags, isDebouncingTags]);

  return {
    entities,
    isEmpty: !topResult && !entities.length && !tags.length,
    isLoading,
    tags,
    topResult,
  };
};

export default useOmnisearchResults;
