import React from 'react';
import getIsTagId from '@/utils/getIsTagId';
import OmnisearchResultsTopTag from './OmnisearchResultsTopTag';
import OmnisearchResultsTopEntity from './OmnisearchResultsTopEntity';

import styles from './Omnisearch.module.scss';

const OmnisearchResultsTop = ({ getItemProps, isSelected, topResult }) => {
  const isTag = getIsTagId(topResult.id || '');
  return (
    <div className={styles.topResult}>
      <div className={styles.resultsTitle}>Top Result</div>
      {isTag ? (
        <OmnisearchResultsTopTag
          tag={topResult}
          selected={isSelected}
          {...getItemProps({
            index: 0,
            item: topResult,
          })}
        />
      ) : (
        <OmnisearchResultsTopEntity
          entity={topResult}
          selected={isSelected}
          {...getItemProps({
            index: 0,
            item: topResult,
          })}
        />
      )}
    </div>
  );
};

export default OmnisearchResultsTop;
