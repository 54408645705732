import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import qs from 'qs';

import { api } from '@/constants';
import { getHash } from '@/utils/format';

export const searchApi = createApi({
  reducerPath: 'searchApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${api.hostname}/api/${api.server}/search` }),
  tagTypes: ['Search'],
  endpoints: (builder) => ({
    getSearch: builder.query({
      query: (query) => `?${qs.stringify(query, { arrayFormat: 'comma' })}`,
      providesTags: (result, error, arg) => [{ type: 'Search', id: getHash(arg) }],
    }),
  }),
});

export const {
  useGetSearchQuery,
  useLazyGetSearchQuery,
} = searchApi;
