import formatCategoryUrnToCssVar from './formatCategoryUrnToCssVar';

const getColorsByTypes = (types) => {
  const categoryColor = types.reduce((color, type) => {
    const nextColor = formatCategoryUrnToCssVar(type);

    return nextColor || color;
  }, '');

  if (!categoryColor) {
    return {};
  }

  return {
    main: `var(--category-${categoryColor})`,
    contrastText: `var(--category-${categoryColor}-secondary)`,
  };
};

export default getColorsByTypes;
