export default {
  $schema: 'http://json-schema.org/draft-07/schema#',
  type: 'object',
  properties: {
    personaCount: {
      type: 'number',
      title: 'Number of personas to generate',
      description: 'How many personas would you like to generate?',
    },
  },
  required: ['personaCount'],
};
