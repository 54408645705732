import { convex } from 'hull.js';
import {
  geoJsonAreaSize, geoJsonAreaSizeMiles, getCentroid, getLLCenter, llre, locationHumanizer, WktToGeoJson,
} from './geoutils';
import isObject from './isObject';
import { ellipsisText } from './format';

const notAddressFeature = (feature) => feature?.place_type?.indexOf('address') === -1
  && feature?.place_type?.indexOf('poi') === -1;

const glibMapboxArea = (features) => {
  // The current strategy is to find the first non-address thing.
  const notAddress = Array.isArray(features)
  && [...features].find((feature) => notAddressFeature(feature));
  const glib = notAddress?.place_name
    || features?.[features.length - 1]?.text;
  if (glib === 'known area') {
    console.log('known area produced because of', features);
  }
  // Remove American zips
  return glib?.replace(/ \d{5},/, '');
};

const getParams = (value) => {
  const wkt = WktToGeoJson(value) || WktToGeoJson(value?.value) || null;

  if (isObject(value) && value.lon && value.lat) {
    return { ll: [value.lon, value.lat].join(',') };
  }

  if (Array.isArray(value) && value.length === 2) {
    return { ll: [...value].reverse().join(',') };
  }

  if (typeof value === 'string' && llre.test(value)) {
    return { ll: value };
  }

  if (wkt) {
    if (wkt.type === 'Polygon') {
      return { area: wkt };
    }

    if (wkt.type === 'MultiPoint') {
      const wktConvexed = {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [convex(wkt?.coordinates)],
        },
      };

      const size = geoJsonAreaSizeMiles(wktConvexed);

      if (size < 2000) {
        const prefix = `${wkt?.coordinates?.length} Multipoints near\u00A0`;
        return { ll: getLLCenter(wktConvexed), prefix };
      }

      return {
        prefix: `${wkt?.coordinates?.length} Multipoints covering ${geoJsonAreaSize(wktConvexed, { unit: 'meters' })}`,
      };
    }

    if (wkt.type === 'Point') {
      return { ll: wkt.coordinates.join(',') };
    }
    if (wkt.type === 'MultiPolygon') {
      return { prefix: 'Complex Multipolygon' };
    }
  }

  return {};
};

const getDescribeLocation = async (location) => {
  if (!location) null;

  // if data is there, return it
  if (isObject(location) && location.label) {
    if (location.displayLabel) {
      return location;
    }

    return {
      ...location,
      displayLabel: locationHumanizer(location),
    };
  }

  const { ll, area: nextArea, prefix } = getParams(location);

  // Legacy code
  let llPoint = ll;
  let size = '';
  if (nextArea && !llPoint) {
    size = `${geoJsonAreaSize(nextArea, { unit: 'meters' })}\u00A0`;
    llPoint = getLLCenter(nextArea);
  }

  if (!llPoint) {
    // Cant get location from the data, return fallback
    const fallbackLabel = location?.value ? ellipsisText(JSON.stringify(location.value)) : '';

    const nextLocation = {
      ...location,
      label: fallbackLabel,
    };

    return {
      ...nextLocation,
      displayLabel: locationHumanizer(nextLocation),
    };
  }

  let result;
  try {
    const response = await fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(llPoint)}.json?access_token=${import.meta.env.VITE_MAPBOX_KEY}`);
    result = await response.json();
  } catch (error) {
    // Mapbox didn't return anything. Return the location label with coordinates
  }
  const glib = result && glibMapboxArea(result?.features);

  let label;

  if (glib) {
    const labelPrefix = prefix || size ? `${size}in\u00A0` : '';
    label = `${labelPrefix}${glib}`;
  } else {
    label = prefix || size ? `${size}around ${getCentroid(location.value)}` : getCentroid(location.value);
  }

  const nextLocation = {
    ...location,
    label,
  };

  return {
    ...nextLocation,
    displayLabel: locationHumanizer(nextLocation),
  };
};

export default getDescribeLocation;
