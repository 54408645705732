import { useSelector } from 'react-redux';
import { useCallback, useMemo, useState } from 'react';
import { useDashboardActionsContext } from '@/screens/Dashboard/DashboardActionsProvider';
import CANONICAL_TAG from '@/utils/canonicalTag';
import { useGetTagsQuery } from '@/store/apis/tagsApi';
import CategoriesDataList, { CategoryDataView } from './CategoriesDataList';
import SelectedCategoryContent from './SelectedCategory/SelectedCategoryContent';
import { HIGHLIGHT_DATA } from './constants';
import styles from './PanelBodyExplore.module.scss';

const PanelBodyExplore = ({
  panelId,
  panel,
  baseParams,
  pagination,
  updateHasMoreExplorePages,
  panelSettings,
}) => {
  const dashboardActions = useDashboardActionsContext();
  const exploreControls = panel.settings?.exploreControls || {};

  const handleSelectedCategory = (newSelectedCategory) => () => {
    const nextExploreControls = {
      ...panelSettings,
      exploreControls: {
        ...(panelSettings?.exploreControls || {}),
        selectedCategory: newSelectedCategory,
      },
    };

    dashboardActions.changePanelSettings({
      panelId,
      nextSettings: nextExploreControls,
    });
  };

  const { isExploreConciseView } = useSelector((state) => state.dashboards);
  const { selectedCategory } = exploreControls;
  const [subCategories, setSubCategories] = useState();
  const { initialSelectedCategory } = exploreControls;

  const validatedSelectedCategory = useMemo(() => {
    setSubCategories({});
    return HIGHLIGHT_DATA.reduce((result, data) => {
      const matchingTag = data.tags.find((tag) => tag.tagValue === selectedCategory);
      if (matchingTag) {
        setSubCategories({ [selectedCategory]: 'include' });
        return data.category;
      }
      return result;
    }, selectedCategory);
  }, [selectedCategory]);

  const filters = useMemo(() => ({
    query: '',
    parentType: validatedSelectedCategory,
    take: 500,
    tags: CANONICAL_TAG,
  }), [validatedSelectedCategory]);

  const { data: tagsResult } = useGetTagsQuery(filters);

  const handleSubCategoryChange = useCallback(
    (keyName, newValue) => {
      if (keyName === selectedCategory) return;
      setSubCategories((prev) => (newValue === null
        ? Object.fromEntries(Object.entries(prev).filter(([key]) => key !== keyName))
        : { ...prev, [keyName]: newValue ? 'include' : 'exclude' }));
    },
    [selectedCategory],
  );

  const handleResetSubcategories = () => {
    setSubCategories({});
  };

  if (selectedCategory) {
    return (
      <div className={styles.container}>
        <SelectedCategoryContent
          panelId={panelId}
          baseParams={baseParams}
          pagination={pagination}
          panel={panel}
          exploreControls={exploreControls}
          selectedCategory={validatedSelectedCategory}
          fixedSubcategory={selectedCategory}
          selectedSubCategories={subCategories}
          handleSelectedCategory={handleSelectedCategory}
          handleSubCategoryChange={handleSubCategoryChange}
          updateHasMoreExplorePages={updateHasMoreExplorePages}
          panelSettings={panelSettings}
          categorySubCategories={tagsResult}
          handleResetSubcategories={handleResetSubcategories}
        />
      </div>
    );
  }

  if (initialSelectedCategory) {
    return (
      <CategoryDataView
        baseParams={baseParams}
        category={initialSelectedCategory}
        onSelect={handleSelectedCategory}
        panel={panel}
        conciseView={isExploreConciseView}
      />
    );
  }

  return (
    <div className={styles.container}>
      <CategoriesDataList
        baseParams={baseParams}
        onSelect={handleSelectedCategory}
        panel={panel}
        visible={!selectedCategory}
      />
    </div>
  );
};

export default PanelBodyExplore;
