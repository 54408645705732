import ReactMarkdown from 'react-markdown';
import tryParseJson from '@/utils/tryParseJson';
import personaForm from '../shared/personaForm';
import personaInsights from '../shared/personaInsights';
import personaSchema from '../shared/personaSchema';
import getPersonaResultMarkdown from '../shared/getPersonaResultMarkdown';

const mapEntity = (entity) => `Entity: ${entity.name}, Subject: ${entity.subtype}, Score: ${entity.query.affinity}`;

const mapTag = (entity) => `Tag Genre: ${entity.name}, Subject: ${entity.subtype}, Score: ${entity.query.affinity}`;

const personas = {
  name: 'Personas',
  description: 'Generate personas with action plans across various industries',
  form: personaForm,
  insights: personaInsights,
  render: ({ result }) => {
    const markdown = getPersonaResultMarkdown(tryParseJson(result.slice(7, -3) || '[]'));
    return (
      <ReactMarkdown>
        {markdown}
      </ReactMarkdown>
    );
  },
  runner: async ({
    entities, fetchGpt, tags, userData,
  }) => {
    const { personaCount } = userData;
    const prompt = `
    1. Create ${personaCount} distinct Personas
    Each persona must directly reflect and be grounded in the provided data. Ensure that any regional, locational, cultural nuances already present in the input data are highlighted.
    Input Data:
    Tags: ${tags.map(mapTag).join(' ')}
    Entities: ${entities.map(mapEntity).join(' ')}

    2. Data Requirements:
    Use only the provided tags, entities, and their affinity scores to define each persona. Avoid creative archetypes or backstories. Do not repeat tags, entities, or venues across personas.

    3. Justifications for Interests:
    Provide explicit reasons why a tag or entity aligns with the persona. Example:
    "This persona connects with Revolution Brewing (score: 0.999) for its community-driven ethos, which aligns with their passion for local cultural events."
    Ensure activities, preferences, and lifestyle align dynamically with the context of the input data (e.g., outdoor activities for nature-related tags, or urban experiences for city-specific data). Avoid including activities or preferences unsupported by the input data.

    4. Key Requirements:
    Minimal Overlap: Avoid repeating entities or tags unless justified by shared overarching themes.
    Specificity: Use affinity scores to justify the selection of all tags and entities.
    Adaptability: Ensure each persona's activities, preferences, and lifestyle adapt to the context inferred from the provided data.

    4. Key Requirements:
    Minimal Overlap: Avoid repeating entities or tags unless justified by shared overarching themes.
    Specificity: Use affinity scores to justify the selection of all tags and entities.
    5.Final Output:

    Return all personas in a JSON array structured according to this schema: ${JSON.stringify(personaSchema)}

    6. Additional Notes:
    - Make sure to dynamically infer and emphasize activities, environments, and preferences based on the provided data's context, ensuring the personas remain contextually relevant.
    `;

    try {
      const gptResult = await fetchGpt({ prompt });
      return gptResult;
    } catch (e) {
      return 'Error';
    }
  },
};

export default personas;
