import React, { useMemo } from 'react';
import Typography from '@mui/material/Typography';
import EntitiesList from '../Omnisearch/EntitiesList';
import CreativesModalResultRendered from './CreativesModalResultRendered';
import styles from './CreativesModal.module.scss';
import CategorizedTags from '../CategorizedTags';

const CitationFooter = ({ entities, tagSectionsByKey }) => (
  <div className={styles.citationFooter}>

    {
      (!!entities || !!tagSectionsByKey) && (
        <>
          <Typography variant="h4">Citations</Typography>
          <Typography variant="h5">
            from Qloo Insights
          </Typography>
        </>
      )
    }

    {!!entities && (
      <EntitiesList entities={entities} showMini />
    )}

    {!!tagSectionsByKey && (
      <div className={styles.resultTagsContainer}>
        {Object.keys(tagSectionsByKey).map((key) => {
          const [category, tagType] = key.split(':');

          const categoryLabel = category !== 'undefined' ? category : null;
          const tagTypeLabel = tagType !== 'undefined' ? tagType : null;
          const label = tagTypeLabel || categoryLabel;
          return (
            <CategorizedTags category={category} key={key} label={label} tags={tagSectionsByKey[key]} limit={99} />
          );
        })}
      </div>
    )}
  </div>
);

const getTagSectionsByKey = (tags) => {
  const result = {};

  tags.forEach((tag) => {
    const { category, tagType } = tag;
    const key = `${category}:${tagType}`;

    if (!result[key]) {
      result[key] = [];
    }

    result[key].push(tag);
  });

  return result;
};

const CreativesModalResult = ({
  baseParams,
  entitiesRequest,
  tagsRequest,
  template,
  runnerRequest,
}) => {
  const entities = entitiesRequest.result || [];
  const result = runnerRequest.result || null;
  const tags = tagsRequest.result || [];

  const tagSectionsByKey = useMemo(() => getTagSectionsByKey(tagsRequest.result || []), [tagsRequest.result]);

  return (
    <div>
      {!!result && (
        <>
          <div className={styles.resultSection}>
            <div className={styles.resultSectionBody}>
              <CreativesModalResultRendered
                baseParams={baseParams}
                entities={entities}
                tags={tags}
                result={result}
                template={template}
              />
            </div>
          </div>
          <CitationFooter entities={entities} tagSectionsByKey={tagSectionsByKey} />
        </>
      )}
    </div>
  );
};

export default CreativesModalResult;
