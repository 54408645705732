import { useEffect } from 'react';

/**
 * Custom hook to prompt the user before unloading the page.
 * @param {boolean} when - Whether to enable the prompt.
 * @param {string} message - The message to display (Note: Most browsers display a generic message).
 */
const useBeforeUnload = (when, message) => {
  useEffect(() => {
    if (!when) return;

    const handleBeforeUnload = (event) => {
      event.preventDefault();
      // eslint-disable-next-line no-param-reassign
      event.returnValue = message; // Most browsers ignore the custom message
      return message;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    // eslint-disable-next-line consistent-return
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [when, message]);
};

export default useBeforeUnload;
