import React, { memo, useCallback, useMemo } from 'react';
import memoize from 'fast-memoize';
import { geoJsonToWkt, WktToGeoJson } from '@/utils/geoutils';

const outgoing = memoize((geoJson) => geoJsonToWkt(geoJson));
const incoming = memoize((wkt) => WktToGeoJson(wkt));

export const MapToWktHOC = (WrappedComponent) => memo(({
  location, onChange, ...props
}) => {
  const nextLocation = useMemo(() => ({
    ...location,
    geoJson: location?.value ? incoming(location.value) : null,
  }), [location]);

  const handleChange = useCallback(
    ({ value, ...rest }) => onChange({ value: outgoing(value), ...rest }),
    [onChange],
  );

  return (
    <WrappedComponent
      {...props}
      location={nextLocation}
      onChange={handleChange}
    />
  );
});

export default MapToWktHOC;
