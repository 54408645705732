import React, { useMemo } from 'react';
import MarkdownView from './MarkdownViewer/MarkdownView';

const CreativesModalResultRendered = ({
  baseParams,
  entities,
  tags,
  template,
  result,
}) => {
  const { result: parsedResult, ...rest } = typeof result === 'string' ? { result } : result;

  const extraCitations = useMemo(
    () => (template.extraCitations ? template.extraCitations({ result: parsedResult, ...rest }) : []),
    [template.extraCitations],
  );

  if (template.render) {
    return template.render({
      baseParams,
      entities,
      extraCitations,
      result: parsedResult,
      tags,
      template,
      ...rest,
    });
  }

  return <MarkdownView>{parsedResult}</MarkdownView>;
};

export default CreativesModalResultRendered;
