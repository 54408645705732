export default {
  title: 'Persona Overview Schema',
  type: 'array',
  minItems: 1,
  required: ['summaries'],
  additionalProperties: false,
  properties: {
    summaries: {
      type: 'array',
      description: 'Based on the different entities and tags provided as input, create distinct three sentence persona summaries that each represent this taste. The personas must directly reflect the provided data, ensuring that the text highlights nuances already present in the data. The personas should also reflect implied regional, cultural, social characteristics.',
      items: {
        type: 'string',
      },
    },
  },
};
