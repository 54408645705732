const fieldTypes = {
  checkboxList: 'checkboxList',
  location: 'location',
  omnisearch: 'omnisearch',
  pillPicker: 'pillPicker',
  pillPickerMultiple: 'pillPickerMultiple',
  slider: 'slider',
  radio: 'radio',
  rangeInputs: 'rangeInputs',
  rating: 'rating',
  ratingRange: 'ratingRange',
  select: 'select',
  text: 'text',
  filterTags: 'filterTags',
  filterTagsParents: 'filterTagsParents',
  audiences: 'audiences',
  entitiesSearch: 'entitiesSearch',
};

export default fieldTypes;
