import {
  Box, Button, Card, CardActionArea, Typography,
} from '@mui/material';
import { AiOutlineTag } from 'react-icons/ai';
import { formatTagCategories } from '@/utils/tagCategories';
import getColorsByTypes from '@/utils/getColorsByTypes';
import { useGetExplainConceptQuery, useGetGenreMusicQuery } from '@/store/apis/gIApi';
import OmnisearchResultsTopAdditionalElement from './OmnisearchResultsTopAdditionalElement';

const commaAnd = (strings) => {
  const count = strings.length;
  return strings.reduce(
    (result, s, index) => {
      return `${result}${index > 0 ? `${count > 2 ? ',' : ''} ${index === count - 1 ? 'and ' : ''}` : ''}${s}`;
    },
    '',
  );
};

const getIsMusicGenre = (urn) => {
  return typeof urn === 'string' && urn.indexOf('urn:tag:genre:music') > -1;
};

const OmnisearchResultsTopTag = ({ tag, selected, ...props }) => {
  const parentsTypes = tag.parents ? tag.parents.map((parent) => parent.type) : [];
  const colors = getColorsByTypes([tag.type, ...parentsTypes]);
  const categories = formatTagCategories(tag.type);

  const isMusicGenre = getIsMusicGenre(tag.id);
  const { data: genreResult } = useGetGenreMusicQuery({ genre: tag.name }, { skip: !isMusicGenre });
  const notableArtists = genreResult?.notableArtists || [];
  const parentGenres = genreResult?.parentGenres || [];
  const parentGenresDescription = tag.name
      && parentGenres.length ? `${tag.name} is a form of ${commaAnd(parentGenres)}` : '';

  const { data: explainConceptData } = useGetExplainConceptQuery({
    name: tag.name, urn: tag.id,
  });
  const shortExplanation = explainConceptData?.explain_short;

  const description = tag?.properties.description
  || genreResult?.description
  || shortExplanation
  || parentGenresDescription
  || '';

  return (
    <Card sx={{ boxShadow: 0 }}>
      <CardActionArea {...props}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="start"
          gap={2}
          p={2}
          lineHeight={1.5}
          bgcolor={selected && colors.contrastText}
        >
          <Box display="flex" width="100%">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              bgcolor={colors.contrastText}
              color={colors.main}
              width="72px"
              height="72px"
              borderRadius={1}
            >
              <AiOutlineTag size={32} color={colors.main || 'hsl(var(--gray))'} />
            </Box>
            <Box flex={1} display="flex" flexDirection="column" pl={1.5}>
              <Typography fontSize="14px" fontWeight="bold">
                {tag.name}
              </Typography>
              <Typography variant="caption" fontWeight="bold" textTransform="capitalize" color={colors.main}>
                {categories}
              </Typography>
            </Box>
          </Box>
          {description && <OmnisearchResultsTopAdditionalElement title="Description" content={description} />}
          {notableArtists && notableArtists.length > 0 && (
            <OmnisearchResultsTopAdditionalElement
              title="Notable Artists"
              content={notableArtists.join(', ')}
            />
          )}
          <Button color="primary" variant="text" size="small" bold font="Inter" sx={{ mt: 1.5 }}>
            {selected ? 'DESELECT' : 'SELECT'}
          </Button>
        </Box>
      </CardActionArea>
    </Card>
  );
};

export default OmnisearchResultsTopTag;
